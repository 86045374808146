import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks";
import { PostMessage } from "../../utils/webFramed";
import { setCRSSubSourceFromURL } from "../../utils/helpers";
import { setRecentSearch } from "../../redux/slices/RecentSearch/RecentSearch";
import { PageProps, navigate } from "gatsby";
import { Spinner } from "react-bootstrap";
import { getRecentSearchDetails } from "../../services/recent-search";
import { customFormatDate } from "../../services/dates";
import WebFramed from "../../components/common/WebFramed/WebFramed";

export interface Occupancy {
  count: number;
  rooms: Room[];
}

export interface Room {
  id: string;
  adults: number;
  children: number;
  childrenAges: number[];
}

const RecentSearch: React.FC<PageProps> = (props) => {
  const dispatch = useAppDispatch();
  const crmBasicProfile = useSelector(
    (state: any) => state.member.crmBasicProfile
  );

  useEffect(() => {
    try {
      setCRSSubSourceFromURL(props.location.search);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const handler = async () => {
      try {
        const res = await getRecentSearchDetails(crmBasicProfile.id);
        const recentSearchDetails = res?.abandonCartbyMemberID?.abandonCart;
        const { rooms = [] } = JSON.parse(recentSearchDetails?.occupancy);
        const searchRooms = rooms.map((item: any) => ({
          adults: item.adults,
          children: item.children,
          childrenAges: item.childrenAges,
        }));
        const queryParams = new URLSearchParams({
          crsCode: recentSearchDetails?.hotelID,
          checkin: customFormatDate(recentSearchDetails?.startDate) || "",
          checkout: customFormatDate(recentSearchDetails?.endDate) || "",
          // adult: totalAdults.toString(),
          // child: totalChildren.toString(),
          // childages: childAges.join(","),
          RoomTypeCode: recentSearchDetails?.roomType,
          promocode: recentSearchDetails?.promoCode,
          groupcode: recentSearchDetails?.groupCode,
          ratePlanFilterCode: recentSearchDetails?.ratePlanFilterCode || "",
          rooms: JSON.stringify(searchRooms || []),
        });
        const url = `/checkout?${queryParams.toString()}`;
        PostMessage(url);

        dispatch(
          setRecentSearch({
            hotelID: recentSearchDetails?.hotelID,
            startDate: recentSearchDetails?.startDate,
            endDate: recentSearchDetails?.endDate,
            roomType: recentSearchDetails?.roomType,
            promocode: recentSearchDetails?.promoCode || "",
            occupancy: recentSearchDetails?.occupancy,
            groupcode: recentSearchDetails?.groupCode || "",
            ratePlanFilterCode: recentSearchDetails?.ratePlanFilterCode || "",
            ...recentSearchDetails,
            isAbandonCart: true,
          })
        );
        navigate(url);
      } catch (error) {
        console.error(error);
        // Handle the error as needed
        PostMessage(
          `Framed web | Get recent search API failed. error: ${JSON.stringify(
            error
          )}`
        );
      }
    };

    if (crmBasicProfile.id) {
      handler();
    }
  }, [crmBasicProfile]);

  return (
    <>
      <WebFramed />
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner animation={"border"} />
      </div>
    </>
  );
};

export default RecentSearch;
