import { customFormatDate } from "../services/dates";
import { isBrowser } from "./helpers";

/**
 * Sent a string | object to react native wev view.
 * @param {string | object} message - The string or object sent to react native web view.
 */
export const PostMessage = (message) => {
  return (
    isBrowser &&
    window?.ReactNativeWebView?.postMessage(JSON.stringify(message))
  );
};

export const formatFramedWebReservationDetails = async (reservation) => {
  const formattedResObject = {
    ID: reservation.iD ? reservation.iD : "",
    Start: reservation.start ? customFormatDate(reservation.start) : "",
    End: reservation.end ? customFormatDate(reservation.end) : "",
    ResGuests:
      reservation.guests && reservation.guests.length
        ? [
            {
              GivenName: reservation.guests[0].givenName,
              Surname: reservation.guests[0].surname,
            },
          ]
        : [{ GivenName: "", Surname: "" }],
    RoomDescription: reservation.roomDescription.name
      ? { Name: reservation.roomDescription.name }
      : { Name: "" },
    RoomRates:
      reservation.roomRates && reservation.roomRates.length
        ? [
            {
              Total: reservation.roomRates[0].total
                ? reservation.roomRates[0].total.toString()
                : "",
            },
          ]
        : [{ Total: "" }],
  };
  return formattedResObject;
};
